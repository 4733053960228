nav {
    position: fixed;
    width: 100%;
    height: 50px;

    display: flex;
    border-bottom: solid 1px;
    background-color: #fff;
    z-index: 100;

}

nav>div {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}