body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  min-height: 100%;
}

#root {
  min-height: 100%;
}

html {
  height: 100%;
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: inherit;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.page {
  display: flex;
  flex-direction: column;
  height: 100%;
  margin: 20px;
}

.container {
  display: flex;
  justify-content: space-between;
}

.text-field {
  flex: 1;
  width: 100%;
  height: 100%;
}

.small-horizontal-margin-div {
  margin: 0 10px;
}

.medium-horizontal-margin-div {
  margin: 0 20px;
}

.small-vertical-margin-div {
  margin: 10px 0;
}

.medium-vertical-margin-div {
  margin: 20px 0;
}

.border-container {
  border: 1px solid #ccc;
  min-height: 30px;
}

.scrollable-nav {
  overflow-x: auto;
  /* Enable horizontal scrolling */
  white-space: nowrap;
  /* Prevent navigation items from wrapping to new lines */
  -webkit-overflow-scrolling: touch;
  /* Enable smooth scrolling on iOS devices */
  background-color: darkcyan;
  color: white;
  font-weight: bold;
}

.scrollable-nav a {
  display: inline-block;
  padding: 10px;
  /* Add more styling as needed */
}
